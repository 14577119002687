<template>
  <section class="max-w-6xl mx-auto">
    <h1 class="text-5xl text-center">Key Features</h1>
    <div class="grid grid-cols-10 gap-6 mt-20">
      <UCard
        v-for="(feature, index) in features"
        :key="index"
        :class="feature.class"
        class="shadow-xl max-w-lg sm:max-w-full mx-auto sm:mx-0"
      >
        <div class="flex flex-col gap-4">
          <UIcon :name="feature.icon" class="text-3xl text-primary" />
          <span class="text-left font-bold text-lg">{{ feature.title }}</span>
          <p>
            {{ feature.description }}
          </p>
        </div>
      </UCard>
    </div>
  </section>
</template>

<script setup lang="ts">
const features = [
  {
    title: "Gamified Learning Experience",
    description:
      "Transform coding exercises into thrilling quests, earning XP and equipment as you progress.",
    class: "col-span-10 sm:col-span-5 md:col-span-6",
    icon: "i-game-icons-retro-controller",
  },
  {
    title: "Epic Boss Fights",
    description:
      "Test your coding skills against formidable bosses and earn rare rewards.",
    class: "col-span-10 sm:col-span-5 md:col-span-4",
    icon: "i-game-icons-boss-key",
  },
  {
    title: "Interactive RPG Elements",
    description:
      "Customize and upgrade your character as you advance through the levels.",
    class: "col-span-10 sm:col-span-5 md:col-span-6",
    icon: "i-game-icons-sword-brandish",
  },
  {
    title: "Community Quests",
    description:
      "Join forces with other learners to complete community challenges and share your victories.",
    class: "col-span-10 sm:col-span-5 md:col-span-4",
    icon: "i-game-icons-team-idea",
  },
  {
    title: "Progressive Skill Development",
    description:
      "Unlock new skills and abilities by completing coding tasks and mini-games.",
    class: "col-span-10 sm:col-span-5 md:col-span-5",
    icon: "i-game-icons-skills",
  },
  {
    title: "Achievement System",
    description:
      "Earn badges and achievements that showcase your coding prowess.",
    class: "col-span-10 sm:col-span-5 md:col-span-5",
    icon: "i-game-icons-achievement",
  },
];
</script>
