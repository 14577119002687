<template>
  <section class="max-w-6xl mx-auto">
    <h1 class="text-5xl text-center">How It Works</h1>
    <div
      class="relative grid justify-between items-start mt-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4"
    >
      <!-- Line Connector (full width) -->
      <div
        class="absolute w-full h-1 bg-primary top-6 transform -translate-y-1/2 z-0 hidden lg:block"
      ></div>

      <!-- Steps -->
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="relative z-10 flex flex-col items-center flex-1 gap-2 max-w-lg"
      >
        <div
          class="z-20 hidden sm:flex items-center bg-primary shadow-xl w-12 h-12 rounded-full mb-1"
        >
          <h1 class="mx-auto font-semibold text-lg text-white">
            {{ index + 1 }}
          </h1>
        </div>
        <UCard
          class="rounded-lg shadow-xl py-5 px-3 text-center w-full"
          :ui="{ body: { padding: 'p-0 sm:p-0' } }"
        >
          <h3 class="mb-1 font-bold text-xl">{{ step.title }}</h3>
          <p class="text-sm">{{ step.description }}</p>
        </UCard>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const steps = [
  {
    icon: "fas fa-user-circle",
    title: "Create Your Character",
    description: "Start by designing your unique RPG character.",
  },
  {
    icon: "fas fa-scroll",
    title: "Begin Your Quests",
    description: "Dive into coding quests that match your skill level.",
  },
  {
    icon: "fas fa-coins",
    title: "Gain XP and Loot",
    description:
      "Earn XP and collect valuable equipment as you solve coding challenges.",
  },
  {
    icon: "fas fa-level-up-alt",
    title: "Level Up",
    description:
      "Strengthen your character by leveling up and unlocking new abilities.",
  },
  {
    icon: "fas fa-dragon",
    title: "Face Epic Bosses",
    description: "Prove your skills by defeating challenging boss exercises.",
  },
  {
    icon: "fas fa-users",
    title: "Join the Community",
    description:
      "Collaborate with other adventurers and participate in community events.",
  },
];
</script>
