<template>
  <section
    class="h-[calc(100vh-var(--header-height))] text-center flex justify-center items-center flex-col gap-10 max-w-3xl mx-auto"
  >
    <p class="text-4xl sm:text-5xl md:text-7xl">
      Embark on Your
      <span class="text-primary">CodeQuest RPG</span> Adventure!
    </p>
    <p class="text-base sm:text-lg">
      Master programming by tackling quests and leveling up your character.
    </p>
    <UButton
      label="Start Your Journey"
      icon="i-fa6-solid-rocket"
      size="xl"
      @click="login"
    />
  </section>
</template>

<script setup lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";

const auth0 = useAuth0();

const login = () => {
  auth0.loginWithRedirect({
    appState: {
      target: "/dashboard",
    },
  });
};
</script>
