<template>
  <section class="max-w-6xl mx-auto text-center">
    <h2 class="text-3xl font-bold mb-8">Testimonials</h2>
    <div class="grid grid-cols-1 md:grid-cols-3 justify-center gap-4 px-4">
      <div
        v-for="(testimonial, index) in testimonials"
        :key="index"
        class="max-w-lg"
      >
        <UCard
          class="rounded-lg shadow-lg h-full"
          :ui="{ body: { base: 'h-full', padding: 'px-4 py-4' } }"
        >
          <div class="flex flex-col justify-between h-full">
            <p class="mb-4">"{{ testimonial.quote }}"</p>
            <div class="flex items-center justify-center">
              <UAvatar
                :src="testimonial.image"
                :alt="testimonial.name"
                class="mr-4"
                size="lg"
              />
              <div class="text-left">
                <p class="font-bold">{{ testimonial.name }}</p>
                <p class="text-sm">{{ testimonial.role }}</p>
              </div>
            </div>
          </div>
        </UCard>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
type Testimonial = {
  quote: string;
  image: string;
  name: string;
  role: string;
};

const testimonials: Testimonial[] = [
  {
    quote:
      "CodeQuestRPG has transformed the way I learn programming. It's fun and engaging!",
    image: "", // Replace with actual image URLs
    name: "John Doe",
    role: "Software Developer",
  },
  {
    quote:
      "The quests are challenging and rewarding. I love leveling up my character!",
    image: "", // Replace with actual image URLs
    name: "Jane Smith",
    role: "Web Developer",
  },
  {
    quote:
      "Facing epic bosses through coding challenges has boosted my confidence in programming.",
    image: "", // Replace with actual image URLs
    name: "Alice Johnson",
    role: "Data Scientist",
  },
];
</script>
