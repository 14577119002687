<template>
  <section class="max-w-3xl mx-auto py-20 flex flex-col items-center gap-4">
    <p class="text-center text-4xl font-semibold">
      Join CodeQuestRPG today and start your coding adventure!
    </p>
    <UButton size="xl" class="font-bold"
      >Try it for free and see how fun learning can be.</UButton
    >
  </section>
</template>
